var _asap$Promise$EventTa;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Promise from './rsvp/promise';
import EventTarget from './rsvp/events';
import denodeify from './rsvp/node';
import all from './rsvp/all';
import allSettled from './rsvp/all-settled';
import race from './rsvp/race';
import hash from './rsvp/hash';
import hashSettled from './rsvp/hash-settled';
import rethrow from './rsvp/rethrow';
import defer from './rsvp/defer';
import { config, configure } from './rsvp/config';
import map from './rsvp/map';
import resolve from './rsvp/resolve';
import reject from './rsvp/reject';
import filter from './rsvp/filter';
import asap from './rsvp/asap';

// defaults
config.async = asap;
config.after = function (cb) {
  return setTimeout(cb, 0);
};

var async = function (callback, arg) {
  return config.async(callback, arg);
};

function on() {
  config['on'].apply(config, arguments);
}

function off() {
  config['off'].apply(config, arguments);
}

// Set up instrumentation through `window.__PROMISE_INTRUMENTATION__`
if (typeof window !== 'undefined' && typeof window['__PROMISE_INSTRUMENTATION__'] === 'object') {
  var callbacks = window['__PROMISE_INSTRUMENTATION__'];
  configure('instrument', true);
  for (var eventName in callbacks) {
    if (callbacks.hasOwnProperty(eventName)) {
      on(eventName, callbacks[eventName]);
    }
  }
}

// the default export here is for backwards compat:
//   https://github.com/tildeio/rsvp.js/issues/434
export default (_asap$Promise$EventTa = {
  asap: asap,
  Promise: Promise,
  EventTarget: EventTarget,
  all: all,
  allSettled: allSettled,
  race: race,
  hash: hash,
  hashSettled: hashSettled,
  rethrow: rethrow,
  defer: defer,
  denodeify: denodeify,
  configure: configure,
  on: on,
  off: off,
  resolve: resolve,
  reject: reject,
  map: map
}, _defineProperty(_asap$Promise$EventTa, 'async', async), _defineProperty(_asap$Promise$EventTa, 'filter', filter), _asap$Promise$EventTa);

export { asap, Promise, EventTarget, all, allSettled, race, hash, hashSettled, rethrow, defer, denodeify, configure, on, off, resolve, reject, map, async, filter };