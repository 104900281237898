function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { noop, resolve, handleMaybeThenable, reject, fulfill, subscribe, FULFILLED, REJECTED, PENDING, getThen } from './-internal';

import { default as OwnPromise } from './promise';
import ownThen from './then';
import ownResolve from './promise/resolve';

var Enumerator = function () {
  function Enumerator(Constructor, input, abortOnReject, label) {
    this._instanceConstructor = Constructor;
    this.promise = new Constructor(noop, label);
    this._abortOnReject = abortOnReject;
    this._isUsingOwnPromise = Constructor === OwnPromise;
    this._isUsingOwnResolve = Constructor.resolve === ownResolve;

    this._init.apply(this, arguments);
  }

  Enumerator.prototype._init = function _init(Constructor, input) {
    var len = input.length || 0;
    this.length = len;
    this._remaining = len;
    this._result = new Array(len);

    this._enumerate(input);
  };

  Enumerator.prototype._enumerate = function _enumerate(input) {
    var length = this.length;
    var promise = this.promise;

    for (var i = 0; promise._state === PENDING && i < length; i++) {
      this._eachEntry(input[i], i, true);
    }

    this._checkFullfillment();
  };

  Enumerator.prototype._checkFullfillment = function _checkFullfillment() {
    if (this._remaining === 0) {
      fulfill(this.promise, this._result);
    }
  };

  Enumerator.prototype._settleMaybeThenable = function _settleMaybeThenable(entry, i, firstPass) {
    var c = this._instanceConstructor;

    if (this._isUsingOwnResolve) {
      var then = getThen(entry);

      if (then === ownThen && entry._state !== PENDING) {
        entry._onError = null;
        this._settledAt(entry._state, i, entry._result, firstPass);
      } else if (typeof then !== 'function') {
        this._settledAt(FULFILLED, i, entry, firstPass);
      } else if (this._isUsingOwnPromise) {
        var promise = new c(noop);
        handleMaybeThenable(promise, entry, then);
        this._willSettleAt(promise, i, firstPass);
      } else {
        this._willSettleAt(new c(function (resolve) {
          return resolve(entry);
        }), i, firstPass);
      }
    } else {
      this._willSettleAt(c.resolve(entry), i, firstPass);
    }
  };

  Enumerator.prototype._eachEntry = function _eachEntry(entry, i, firstPass) {
    if (entry !== null && typeof entry === 'object') {
      this._settleMaybeThenable(entry, i, firstPass);
    } else {
      this._setResultAt(FULFILLED, i, entry, firstPass);
    }
  };

  Enumerator.prototype._settledAt = function _settledAt(state, i, value, firstPass) {
    var promise = this.promise;

    if (promise._state === PENDING) {
      if (this._abortOnReject && state === REJECTED) {
        reject(promise, value);
      } else {
        this._setResultAt(state, i, value, firstPass);
        this._checkFullfillment();
      }
    }
  };

  Enumerator.prototype._setResultAt = function _setResultAt(state, i, value, firstPass) {
    this._remaining--;
    this._result[i] = value;
  };

  Enumerator.prototype._willSettleAt = function _willSettleAt(promise, i, firstPass) {
    var _this = this;

    subscribe(promise, undefined, function (value) {
      return _this._settledAt(FULFILLED, i, value, firstPass);
    }, function (reason) {
      return _this._settledAt(REJECTED, i, reason, firstPass);
    });
  };

  return Enumerator;
}();

export default Enumerator;


export function setSettledResult(state, i, value) {
  this._remaining--;
  if (state === FULFILLED) {
    this._result[i] = {
      state: 'fulfilled',
      value: value
    };
  } else {
    this._result[i] = {
      state: 'rejected',
      reason: value
    };
  }
}